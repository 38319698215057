import Container from "components/Container";
import Header from "components/Header";
import Layout from "components/Layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { rgba } from "polished";
import React from "react";
import styled from "@emotion/styled";
import posed, { PoseGroup } from "react-pose";

const Hero = styled.div`
  padding: 2rem 0;
`;

const Main = styled.div`
  padding: 4rem 0;
`;

const ProjectDate = styled.div`
  font-weight: bold;
`;

const Content = styled.div``;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tag = styled.div`
  border: 1px solid hsla(0, 0%, 0%, 0.8);
  border-radius: 50rem;
  font-size: 0.8rem;
  margin: 0 0.25rem 0.25rem;
  padding: 0.25rem 0.75rem;
`;

const ProjectLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
`;

const ProjectLink = styled.a`
  background-color: hsla(0, 0%, 0%, 0.8);
  box-shadow: 0 10px 20px ${rgba("#c0c0c0", 0.5)};
  border-radius: 50rem;
  color: white;
  display: block;
  font-weight: bold;
  margin: 0 0.5rem 1rem;
  padding: 0.75rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 15px 20px #c0c0c0;
    transform: translateY(-5px);
  }
`;

const MediasWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  margin-top: 4rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  a,
  img,
  video {
    display: block;
    width: 100%;
  }

  a {
    box-shadow: 0 10px 20px #c0c0c0;
  }
`;

const Media = styled.div`
  box-shadow: 0 10px 20px ${rgba("#c0c0c0", 0.5)};
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 15px 20px #c0c0c0;
    transform: translateY(-5px);
  }
`;

export default ({ pageContext: { slug, left, right }, data: { markdownRemark: projectNode } }) => (
  <Layout seo={{ postPath: slug, postNode: projectNode }}>
    <Header title={projectNode.frontmatter.title} cover={projectNode.fields.cover}>
      <ProjectDate>{projectNode.frontmatter.date}</ProjectDate>
      <p>{projectNode.frontmatter.headline}</p>
      <TagList>
        {projectNode.frontmatter.tags &&
          projectNode.frontmatter.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
      </TagList>
    </Header>
    <Container>
      <Main>
        <Content>
          <div dangerouslySetInnerHTML={{ __html: projectNode.html }} />
          {projectNode.frontmatter.links && (
            <ProjectLinks>
              {projectNode.frontmatter.links.map((link, index) => (
                <ProjectLink key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </ProjectLink>
              ))}
            </ProjectLinks>
          )}
          {projectNode.frontmatter.medias && (
            <MediasWrapper>
              {projectNode.frontmatter.medias.map((media, index) => (
                <Media key={index}>
                  {media.internal.mediaType.startsWith("image/") && (
                    <a href={media.publicURL} target="_blank" rel="noopener noreferrer">
                      <Img fluid={media.childImageSharp.fluid} />
                    </a>
                  )}
                  {media.internal.mediaType.startsWith("video/") && (
                    <video controls>
                      <source src={media.publicURL} />
                    </video>
                  )}
                </Media>
              ))}
            </MediasWrapper>
          )}
        </Content>
      </Main>
    </Container>
  </Layout>
);

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM YYYY")
        headline
        tags
        links {
          title
          url
        }
        medias {
          publicURL
          internal {
            mediaType
          }
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
      }
      fields {
        slug
        sourceInstanceName
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200, height: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
